import React, { SVGProps } from 'react'

function FlatIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='52'
      height='56'
      fill='none'
      viewBox='0 0 52 56'
      {...props}
    >
      <path
        stroke={props.color || '#6C7D84'}
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M42.279 53.449H9.72a2.546 2.546 0 01-2.539-2.54V6.519h37.635V50.91a2.546 2.546 0 01-2.538 2.539z'
      ></path>
      <path
        stroke={props.color || '#6C7D84'}
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M22.001 53.449v-8.26c0-1.138.858-2.07 1.907-2.07h4.29c1.048 0 1.907.932 1.907 2.07v8.26H22z'
      ></path>
      <path
        stroke={props.color || '#6C7D84'}
        strokeMiterlimit='10'
        strokeWidth='1.884'
        d='M44.614 2.552H7.386A1.868 1.868 0 005.518 4.42v.23c0 1.031.836 1.868 1.868 1.868h37.228a1.868 1.868 0 001.867-1.869v-.23a1.868 1.868 0 00-1.867-1.867z'
      ></path>
      <path
        stroke={props.color || '#6C7D84'}
        strokeMiterlimit='10'
        d='M17.448 10.263h-5.53a.925.925 0 00-.925.926v5.53c0 .511.414.925.925.925h5.53a.925.925 0 00.926-.925v-5.53a.925.925 0 00-.926-.926zM28.765 10.263h-5.53a.925.925 0 00-.926.926v5.53c0 .511.414.925.925.925h5.53a.925.925 0 00.926-.925v-5.53a.925.925 0 00-.925-.926zM40.081 10.263h-5.53a.925.925 0 00-.925.926v5.53c0 .511.414.925.925.925h5.53a.925.925 0 00.926-.925v-5.53a.925.925 0 00-.926-.926zM17.448 21.127h-5.53a.925.925 0 00-.925.926v5.53c0 .511.414.926.925.926h5.53a.925.925 0 00.926-.926v-5.53a.925.925 0 00-.926-.926zM28.765 21.127h-5.53a.925.925 0 00-.926.926v5.53c0 .511.414.926.925.926h5.53a.925.925 0 00.926-.926v-5.53a.925.925 0 00-.925-.926zM40.081 21.127h-5.53a.925.925 0 00-.925.926v5.53c0 .511.414.926.925.926h5.53a.925.925 0 00.926-.926v-5.53a.925.925 0 00-.926-.926zM17.448 31.992h-5.53a.925.925 0 00-.925.925v5.53c0 .511.414.926.925.926h5.53a.925.925 0 00.926-.926v-5.53a.925.925 0 00-.926-.925zM28.765 31.992h-5.53a.925.925 0 00-.926.925v5.53c0 .511.414.926.925.926h5.53a.925.925 0 00.926-.926v-5.53a.925.925 0 00-.925-.925zM40.081 31.992h-5.53a.925.925 0 00-.925.925v5.53c0 .511.414.926.925.926h5.53a.925.925 0 00.926-.926v-5.53a.925.925 0 00-.926-.925z'
      ></path>
    </svg>
  )
}

export default FlatIcon
