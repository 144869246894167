import React, { SVGProps } from 'react'

function ChevronLeftIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='18'
      fill='none'
      viewBox='0 0 11 18'
      {...props}
    >
      <path
        fill={props.color || '#E872C8'}
        d='M.24 8.95c.01-.333.127-.616.39-.87L8.249.63A1.06 1.06 0 019.04.307c.635 0 1.133.488 1.133 1.123 0 .312-.127.595-.342.82l-6.865 6.7 6.865 6.698c.215.215.342.498.342.811 0 .645-.498 1.133-1.133 1.133a1.06 1.06 0 01-.791-.322L.631 9.818C.367 9.564.24 9.281.24 8.95z'
      ></path>
    </svg>
  )
}

export default ChevronLeftIcon
