import React, { SVGProps } from 'react'

function Icon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill={props.color || '#E872C8'}
        d='M12 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-1.8C18 6.57 15.35 4 12 4s-6 2.57-6 6.2c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14zM12 2c4.2 0 8 3.22 8 8.2 0 3.32-2.67 7.25-8 11.8-5.33-4.55-8-8.48-8-11.8C4 5.22 7.8 2 12 2z'
      ></path>
      <mask
        id='mask0_1876_17354'
        style={{ maskType: 'alpha' }}
        width='16'
        height='5'
        x='4'
        y='19'
        maskUnits='userSpaceOnUse'
      >
        <path
          fill='#D9D9D9'
          d='M17 19l-4 3h-2l-1.5-1L7 19l-3 2.5L6 24h12.5l1-3-2.5-2z'
        ></path>
      </mask>
      <g mask='url(#mask0_1876_17354)'>
        <path
          stroke={props.color || '#E872C8'}
          strokeWidth='1.5'
          d='M18.25 21.5c0 .039-.02.177-.284.4-.26.22-.682.452-1.268.662-1.167.416-2.828.688-4.698.688-1.87 0-3.531-.272-4.697-.689-.587-.209-1.008-.44-1.269-.66-.264-.224-.284-.362-.284-.401 0-.039.02-.177.284-.4.26-.22.682-.452 1.269-.662 1.166-.416 2.827-.688 4.697-.688s3.531.272 4.698.689c.586.209 1.008.44 1.268.66.264.224.284.362.284.401z'
        ></path>
      </g>
    </svg>
  )
}

export default Icon
