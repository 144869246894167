import React, { SVGProps } from 'react'

function ChevronRightIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='18'
      fill='none'
      viewBox='0 0 11 18'
      {...props}
    >
      <path
        fill={props.color || '#E872C8'}
        d='M10.75 8.95c0 .331-.117.614-.38.868L2.751 17.27a1.075 1.075 0 01-.791.322 1.124 1.124 0 01-1.143-1.133c0-.313.137-.596.342-.81l6.875-6.7L1.16 2.25a1.178 1.178 0 01-.342-.82c0-.635.508-1.123 1.143-1.123.312 0 .576.107.79.322L10.37 8.08c.254.254.381.537.381.87z'
      ></path>
    </svg>
  )
}

export default ChevronRightIcon
