import React, { SVGProps } from 'react'

function CreditCardIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width || '24'}
      height={props.height || '24'}
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill={'#fff'}
        d='M21 10V9H3v1zm0 1H3v5.5A1.5 1.5 0 004.5 18h15a1.5 1.5 0 001.5-1.5zm0-3v-.5A1.5 1.5 0 0019.5 6h-15A1.5 1.5 0 003 7.5V8zm-4 8.733a2.001 2.001 0 110-3.465 2 2 0 110 3.465zm-.75-.764A1.991 1.991 0 0116 15c0-.351.09-.682.25-.969a1.001 1.001 0 100 1.938zM2 7.5A2.5 2.5 0 014.5 5h15A2.5 2.5 0 0122 7.5v9a2.5 2.5 0 01-2.5 2.5h-15A2.5 2.5 0 012 16.5zM18 16a1 1 0 100-2 1 1 0 000 2z'
      ></path>
    </svg>
  )
}

export default CreditCardIcon
