import React, { SVGProps } from 'react'

function TerracedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100'
      height='52'
      fill='none'
      viewBox='0 0 100 52'
      {...props}
    >
      <path
        stroke={props.color || '#E872C8'}
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M22.483 46.796V35.537c0-1.139.858-2.071 1.907-2.071h4.29c1.048 0 1.907.932 1.907 2.071v11.26h-8.104zM45.948 46.796V35.537c0-1.139.859-2.071 1.908-2.071h4.288c1.05 0 1.908.932 1.908 2.071v11.26h-8.104zM93.529 24.448L74.794 5.714a1.754 1.754 0 00-1.302-.51 1.754 1.754 0 00-1.301.51L61.76 16.144 51.275 5.715a1.754 1.754 0 00-1.301-.51 1.754 1.754 0 00-1.302.51L38.24 16.144 27.81 5.715a1.754 1.754 0 00-1.302-.51 1.754 1.754 0 00-1.302.51L6.472 24.448a1.768 1.768 0 000 2.493 1.768 1.768 0 002.492 0L26.508 9.398 38.241 21.13 49.974 9.398l11.733 11.634L73.492 9.398 91.036 26.94a1.768 1.768 0 002.493 0 1.768 1.768 0 000-2.492zM69.467 46.796V35.537c0-1.139.859-2.071 1.908-2.071h4.288c1.05 0 1.908.932 1.908 2.071v11.26h-8.104zM38.188 46.796V21.032M61.76 46.796V21.032'
      ></path>
      <path
        stroke={props.color || '#E872C8'}
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M73.492 9.34L61.76 21.031 49.974 9.34 38.241 21.032 26.508 9.34 11.69 24.487v19.77a2.546 2.546 0 002.54 2.54h71.54a2.546 2.546 0 002.54-2.54V24.162L73.492 9.34z'
      ></path>
    </svg>
  )
}

export default TerracedIcon
