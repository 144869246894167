import React, { SVGProps } from 'react'

function SafetyHelmetIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='16'
      fill='none'
      viewBox='0 0 22 16'
      {...props}
    >
      <path
        fill='#E872C8'
        d='M20.884 11.429h-.393c.063-2-.514-3.971-1.65-5.642a9.963 9.963 0 00-4.698-3.692v-.122c0-.523-.215-1.025-.596-1.395-.382-.37-.9-.578-1.44-.578H9.893c-.54 0-1.057.208-1.439.578-.381.37-.596.872-.596 1.395v.122A9.963 9.963 0 003.16 5.787a9.499 9.499 0 00-1.65 5.642h-.393a1.137 1.137 0 00-.792.314 1.072 1.072 0 00-.324.768v2.407a1.046 1.046 0 00.324.767 1.112 1.112 0 00.792.315h19.768a1.139 1.139 0 00.791-.315 1.047 1.047 0 00.325-.767v-2.407a1.047 1.047 0 00-.325-.768 1.112 1.112 0 00-.79-.314zm-.456 3.047H1.572v-1.524h.786a.809.809 0 00.59-.259.757.757 0 00.196-.594 7.987 7.987 0 01.97-4.925 8.35 8.35 0 013.744-3.456v.853a.75.75 0 00.23.54.798.798 0 00.556.222c.208 0 .408-.08.555-.223a.75.75 0 00.23-.539V1.973a.43.43 0 01.134-.32.457.457 0 01.33-.13h2.216a.468.468 0 01.33.13.44.44 0 01.133.32v2.598a.75.75 0 00.23.54.798.798 0 00.556.222c.209 0 .408-.08.556-.223a.75.75 0 00.23-.539V3.75a8.334 8.334 0 013.748 3.454 7.972 7.972 0 01.966 4.926.742.742 0 00.189.595.788.788 0 00.597.228h.785v1.524z'
      ></path>
    </svg>
  )
}

export default SafetyHelmetIcon
