import React, { SVGProps } from 'react'

function ChevronDownIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      fill='none'
      viewBox='0 0 22 22'
      {...props}
    >
      <path
        fill={props.color || '#6C7D84'}
        d='M11 14.474c-.29 0-.531-.1-.755-.332l-4.923-4.98a.909.909 0 01-.274-.665c0-.54.424-.97.955-.97.266 0 .506.115.697.306l4.3 4.358 4.3-4.358a.983.983 0 01.697-.307c.531 0 .955.432.955.971a.895.895 0 01-.274.664l-4.923 4.98c-.224.225-.465.333-.755.333z'
      ></path>
    </svg>
  )
}

export default ChevronDownIcon
