import React, { SVGProps } from 'react'

function CartIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill={props.color || '#E872C8'}
        d='M8 18c-1.1 0-1.99.9-1.99 2S6.9 22 8 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-8.9-5h7.45c.75 0 1.41-.41 1.75-1.03L22 4.96 20.25 4l-3.7 7H9.53L5.27 2H2v2h2l3.6 7.59-1.35 2.44C5.52 15.37 6.48 17 8 17h12v-2H8l1.1-2zM13 2l4 4-4 4-1.41-1.41L13.17 7H9V5h4.17l-1.59-1.59L13 2z'
      ></path>
    </svg>
  )
}

export default CartIcon
