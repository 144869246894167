import React, { SVGProps } from 'react'

function BarsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='14'
      fill='none'
      viewBox='0 0 16 14'
      {...props}
    >
      <path
        fill={props.color || '#fff'}
        d='M1 14a1 1 0 010-2h14a1 1 0 010 2H1zm0-6a1 1 0 010-2h8a1 1 0 010 2H1zm0-6a1 1 0 010-2h14a1 1 0 110 2H1z'
      ></path>
    </svg>
  )
}

export default BarsIcon
