import React from 'react'

function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width || '146'}
      height={props.height || '81'}
      fill='none'
      viewBox='0 0 146 81'
      {...props}
    >
      <path
        fill='#50D7C8'
        d='M104.95 0C89.577-.026 76.225 8.61 69.468 21.291L13.21 30.874l52.642.087 15.364.021h.126a25.41 25.41 0 011.47-3.102l-.112-.09c4.347-7.797 12.67-13.071 22.225-13.054 14.027.022 25.377 11.414 25.356 25.44a25.437 25.437 0 01-.753 6.006l-11.315-11.777-15.036 3.504-4.481 14.771 11.744 12.227a25.39 25.39 0 01-5.593.627c-8.344-.013-15.72-4.067-20.339-10.288l.043-.048a25.362 25.362 0 01-3.279-5.988h-.086l-15.364-.026-57.292-.09L0 49.08l69.684 10.315c6.804 12.421 19.976 20.854 35.137 20.875 22.159.04 40.154-17.903 40.193-40.068C145.048 18.033 127.11.035 104.95 0z'
      ></path>
      <path
        fill='#50D7C8'
        d='M71.442 10.71l-29.517 5.477 25.37.039a44.604 44.604 0 014.147-5.516z'
      ></path>
    </svg>
  )
}

export default Icon
