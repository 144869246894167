import React, { SVGProps } from 'react'

function BadgeCheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      fill='none'
      viewBox='0 0 32 32'
      {...props}
    >
      <path
        stroke={props.color || '#50D7C8'}
        strokeWidth='1.5'
        d='M18.063 7.332A3.775 3.775 0 0016 6.75c-.752 0-1.447.19-2.063.582a4.168 4.168 0 00-1.107 1.024 3.982 3.982 0 00-1.471.07 3.653 3.653 0 00-1.9 1.034l.531.53-.53-.53c-.533.533-.889 1.157-1.033 1.866a4.158 4.158 0 00-.07 1.504 4.167 4.167 0 00-1.025 1.107A3.775 3.775 0 006.75 16c0 .752.19 1.447.582 2.063.276.434.619.804 1.024 1.107-.068.497-.044 1 .07 1.504.145.71.501 1.333 1.034 1.866.533.533 1.157.889 1.866 1.033.504.115 1.007.14 1.504.07.303.406.673.749 1.107 1.025A3.774 3.774 0 0016 25.25c.752 0 1.447-.19 2.063-.582a4.167 4.167 0 001.107-1.024c.497.068 1 .044 1.504-.07.71-.145 1.333-.501 1.866-1.034.533-.533.889-1.157 1.033-1.866.115-.504.14-1.007.07-1.504a4.167 4.167 0 001.025-1.107A3.774 3.774 0 0025.25 16c0-.752-.19-1.447-.582-2.063a4.166 4.166 0 00-1.024-1.107c.068-.49.045-.981-.07-1.47a3.653 3.653 0 00-1.034-1.9c-.533-.533-1.157-.889-1.866-1.033a4.158 4.158 0 00-1.504-.07 4.167 4.167 0 00-1.107-1.025z'
      ></path>
      <path
        fill={props.color || '#50D7C8'}
        d='M20.708 14.537c.39-.425.39-.85 0-1.275-.472-.35-.944-.35-1.416 0l-3.959 3.563-1.625-1.462c-.472-.35-.944-.35-1.416 0-.39.425-.39.85 0 1.274l2.333 2.1c.472.35.944.35 1.417 0l4.666-4.2z'
      ></path>
    </svg>
  )
}

export default BadgeCheckIcon
