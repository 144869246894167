import React, { SVGProps } from 'react'

function ArrowLeftIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill={props.color || '#E872C8'}
        d='M2.387 11.262l6.875-6.875c.286-.258.616-.387.988-.387s.702.129.988.387c.258.286.387.616.387.988s-.129.702-.387.988l-4.554 4.512h13.191c.401 0 .73.129.988.387s.387.587.387.988-.129.73-.387.988-.587.387-.988.387H6.684l4.554 4.512c.258.286.387.616.387.988s-.129.702-.387.988a1.434 1.434 0 01-.988.387c-.372 0-.702-.129-.988-.387l-6.875-6.875A1.434 1.434 0 012 12.25c0-.372.129-.702.387-.988z'
      ></path>
    </svg>
  )
}

export default ArrowLeftIcon
