import React, { SVGProps } from 'react'

function FingerCrossIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      fill='none'
      viewBox='0 0 32 32'
      {...props}
    >
      <path
        stroke={props.color || '#50D7C8'}
        d='M13.167 7.919v-.001c-.182-.395-.48-.691-.894-.83a1.602 1.602 0 00-1.147.047l-.009.003-.008.004c-.395.182-.691.48-.83.894-.128.387-.106.783.041 1.167l.007.017.008.017 1.328 2.822.454.965.451-.966 1.162-2.49.098-.21-.096-.21-.565-1.23zm3.396 9.904v.024c-.02.57-.209 1.006-.551 1.349-.343.342-.78.53-1.348.55h-1.832a.285.285 0 01-.044-.007.259.259 0 01-.004-.024.262.262 0 01.004-.024.298.298 0 01.044-.007h1.84c.502 0 .942-.181 1.283-.545.364-.34.545-.78.545-1.283 0-.502-.181-.943-.545-1.284a1.705 1.705 0 00-1.283-.544h-2.94c-.716.027-1.336.284-1.83.777-.493.494-.75 1.114-.777 1.83v.914h0v.01a4.801 4.801 0 001.403 3.36h0l.005.005.365.366h0l.004.003c1.133 1.108 2.506 1.68 4.094 1.704H17.077c1.623-.048 2.998-.615 4.091-1.707 1.093-1.093 1.659-2.468 1.707-4.091v-1.23l-.77.496c-.223.143-.482.219-.793.219-.362 0-.665-.101-.928-.298l-.006-.005-.006-.004a1.457 1.457 0 01-.575-.771l-.203-.644-.557.382a1.56 1.56 0 01-.912.277c-.31 0-.57-.076-.792-.219l-.77-.495v.916zm-.84-1.543l.777.518v-1.741l-.046-.099-.365-.797-.448-.978-.459.974-.53 1.129-.273.578.627.125c.271.054.509.152.717.29zm-1.782-.315h.318l.135-.287 3.022-6.442.007-.016.007-.017c.147-.384.17-.78.04-1.167a1.507 1.507 0 00-.781-.887l-.022-.01-.023-.01a1.696 1.696 0 00-1.167-.04c-.414.138-.711.433-.893.828 0 0 0 0 0 0l-3.42 7.337-.332.711h3.109zm3.067-3.21c-.303.302-.445.689-.445 1.116v2.125c0 .428.142.815.445 1.118.303.303.69.445 1.117.445.428 0 .814-.142 1.117-.445.303-.303.445-.69.445-1.118v-2.125c0-.427-.142-.814-.445-1.117a1.533 1.533 0 00-1.117-.445c-.428 0-.814.143-1.117.445zm3.187 1.062c-.303.303-.445.69-.445 1.117v2.125c0 .428.142.814.445 1.117.303.303.69.446 1.117.446.428 0 .815-.143 1.118-.446.303-.303.445-.689.445-1.117v-2.125c0-.428-.142-.814-.445-1.117a1.533 1.533 0 00-1.117-.446c-.428 0-.815.143-1.118.446z'
      ></path>
    </svg>
  )
}

export default FingerCrossIcon
