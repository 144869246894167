import React, { SVGProps } from 'react'

function LocationPinIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='24'
      fill='none'
      viewBox='0 0 26 24'
      {...props}
    >
      <path
        fill={props.color || '#E872C8'}
        d='M13.061 12c-1.141 0-2.075-.9-2.075-2s.934-2 2.075-2c1.142 0 2.076.9 2.076 2s-.934 2-2.076 2zm6.228-1.8c0-3.63-2.75-6.2-6.228-6.2-3.477 0-6.227 2.57-6.227 6.2 0 2.34 2.024 5.44 6.227 9.14 4.204-3.7 6.228-6.8 6.228-9.14zM13.06 2c4.36 0 8.303 3.22 8.303 8.2 0 3.32-2.77 7.25-8.303 11.8-5.532-4.55-8.303-8.48-8.303-11.8 0-4.98 3.944-8.2 8.303-8.2z'
      ></path>
    </svg>
  )
}

export default LocationPinIcon
