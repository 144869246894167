import React, { SVGProps } from 'react'

function CircleCheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={props.width || 20}
      height={props.height || 20}
      fill='none'
      viewBox='0 0 20 20'
      {...props}
    >
      <circle cx='10' cy='10' r='10' fill='#E872C8'></circle>
      <path
        fill='#fff'
        d='M15.245 8.059c.458-.5.458-1 0-1.5-.555-.412-1.111-.412-1.667 0L8.922 10.75 7.01 9.03c-.556-.412-1.111-.412-1.667 0-.457.5-.457 1 0 1.5L8.088 13c.556.412 1.111.412 1.667 0l5.49-4.941z'
      ></path>
    </svg>
  )
}

export default CircleCheckIcon
