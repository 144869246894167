import React, { SVGProps } from 'react'

function CirclesIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='132'
      height='132'
      fill='none'
      viewBox='0 0 132 132'
      {...props}
    >
      <circle cx='66' cy='66' r='66' fill='#fff' fillOpacity='0.03'></circle>
      <circle
        cx='66'
        cy='66'
        r='65.284'
        stroke='#fff'
        strokeOpacity='0.05'
        strokeWidth='1.433'
      ></circle>
      <circle
        cx='66'
        cy='66'
        r='46.058'
        fill='#fff'
        fillOpacity='0.05'
      ></circle>
      <circle
        cx='66'
        cy='66'
        r='45.558'
        stroke='#fff'
        strokeOpacity='0.05'
      ></circle>
      <path
        fill='#E872C8'
        fillRule='evenodd'
        d='M66.475 76.92c5.77 0 10.446-4.676 10.446-10.446 0-5.769-4.677-10.446-10.446-10.446S56.03 60.705 56.03 66.474c0 5.77 4.677 10.446 10.446 10.446z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}

export default CirclesIcon
