import React, { SVGProps } from 'react'

function ChevronUpIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='8'
      fill='none'
      viewBox='0 0 12 8'
      {...props}
    >
      <path
        fill={props.color || '#6C7D84'}
        d='M6 .526c.291 0 .532.1.756.332l4.922 4.98a.909.909 0 01.274.665c0 .54-.423.97-.954.97a.983.983 0 01-.698-.306L6 2.809 1.7 7.167a.983.983 0 01-.697.307.958.958 0 01-.954-.971c0-.266.091-.482.274-.664L5.245.859C5.47.633 5.71.525 6 .525z'
      ></path>
    </svg>
  )
}

export default ChevronUpIcon
