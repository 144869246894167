import React, { SVGProps } from 'react'

function SemiDetachedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='52'
      height='52'
      fill='none'
      viewBox='0 0 52 52'
      {...props}
    >
      <path
        stroke={props.color || '#6C7D84'}
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M38.279 46.823H13.72a2.546 2.546 0 01-2.538-2.538V24.188L26 9.367l14.817 14.82v20.098a2.546 2.546 0 01-2.538 2.538z'
      ></path>
      <path
        stroke={props.color || '#6C7D84'}
        strokeMiterlimit='10'
        strokeWidth='2'
        d='M46.036 24.421L27.301 5.686A1.754 1.754 0 0026 5.178a1.754 1.754 0 00-1.302.508L5.963 24.421a1.768 1.768 0 000 2.493 1.768 1.768 0 002.493 0L26 9.37l17.544 17.544a1.768 1.768 0 002.492 0 1.768 1.768 0 000-2.493zM22.027 46.823V35.564c0-1.139.859-2.07 1.908-2.07h4.289c1.049 0 1.907.931 1.907 2.07v11.26h-8.104z'
      ></path>
    </svg>
  )
}

export default SemiDetachedIcon
